import firebase from "firebase/app";

export const schoolCollectionKey = "schools";
export const schoolConfigCollectionKey = "schoolConfigs";
export const schoolSecretCollectionKey = "schoolSecrets";

export type School = {
  ref: firebase.firestore.DocumentReference;
  data: SchoolData;
};

export type SchoolData = {
  id: string; // 塾管理用ID (unique)
  name: string; // 塾名
  owner: firebase.firestore.DocumentReference | null; // 塾 owner スタッフのreference
  admins: string[]; // 塾に所属する owner | admin 権限を持つスタッフのID一覧 (security rule 用)
  supervisors?: string[]; // 塾に所属する supervisor 権限を持つスタッフのID一覧 (security rule 用)
  employees?: string[]; // 塾に所属する employee 権限を持つスタッフのID一覧 (security rule 用)
  tutors: string[]; // 塾に所属するスタッフのID一覧 (security rule 用)
  studentCount: number; // 塾に所属する生徒数
  classroomCount: number; // 塾に存在する教室数
  suspended?: boolean; // アカウント停止状態
  labels?: firebase.firestore.DocumentReference[]; // 教室ごとの labels を定義
  allocatedStorageSize?: number; // Storage 使用量（MB）
};

export type SchoolConfig = {
  ref: firebase.firestore.DocumentReference;
  data: SchoolConfigData;
};

export type Role = "serviceProvider" | null;

export type ParentSchool = {
  schoolDocRef: firebase.firestore.DocumentReference | string;
  schoolDocId: string;
  activateTime?: number; // 利用開始日時（unixtime）
  deactivateTime?: number; // 利用中止日時（unixtime）
};

export type ChildrenSchool = {
  schoolDocRef: firebase.firestore.DocumentReference | string;
  schoolDocId: string;
};

export interface SchoolDocumentReferences {
  schoolRef: firebase.firestore.DocumentReference;
  configRef: firebase.firestore.DocumentReference;
  secretRef: firebase.firestore.DocumentReference;
}

export type SchoolConfigData = {
  paymentType: "uncontracted" | "stripe" | "external" | "none"; // 決済形態
  customerEmail: string; // stripe customer email
  recessTime?: number; // 休会日時(unixtime)
  activateTime?: number; // 利用開始日時(unixtime)
  allowCreateRoom?: boolean; // 学習室作成権限
  allowUseOfCustomOfficialLine?: boolean; // カスタム公式LINEの利用許可
  lineOfficialAccountChannelAccessToken?: string; // LINE公式アカウトのチャネルアクセストークン
  lineOfficialAccountChannelSecret?: string; // LINE公式アカウトのチャネルシークレット
  liffId?: string; // LIFF ID
  target: firebase.firestore.DocumentReference;
  zoomUserId?: string;
  role?: Role[];
  useSchoolAiExclusively?: boolean;
  hideLoginInvitaion?: boolean;
  hideMingakuStudyRoom?: boolean;
  allowStudentsToChangePassword?: boolean;
  childrenSchools?: ChildrenSchool[];
  parentSchools?: ParentSchool[];
  childrenSchoolDocIds?: string[];
  parentSchoolDocIds?: string[];
  isPromptCreationForbidden?: boolean;
};

export type SchoolSecret = {
  ref: firebase.firestore.DocumentReference;
  data: SchoolSecretData;
};

export type SchoolSecretData = {
  customerId: string; // stripe customer id
  paymentMethods: string[]; // stripe attached payment method ids
};

export function convertToSchool(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): School {
  const returnData: SchoolData = {
    id: data.id,
    name: data.name,
    owner: data.owner,
    admins: data.admins,
    supervisors: data.supervisors,
    employees: data.employees,
    tutors: data.tutors,
    labels: data.labels,
    studentCount: data.studentCount,
    classroomCount: data.classroomCount
  };

  if (data.suspended) {
    returnData.suspended = data.suspended;
  }
  if (data.allocatedStorageSize) {
    returnData.allocatedStorageSize = data.allocatedStorageSize;
  }

  return {
    ref,
    data: returnData
  };
}

export function convertToSchoolConfig(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): SchoolConfig {
  const returnData: SchoolConfigData = {
    paymentType: data.paymentType,
    customerEmail: data.customerEmail,
    target: data.target
  };

  if (data.recessTime) {
    returnData.recessTime = data.recessTime;
  }

  if (data.activateTime) {
    returnData.activateTime = data.activateTime;
  }

  if (data.allowCreateRoom) {
    returnData.allowCreateRoom = data.allowCreateRoom;
  }

  if (data.allowUseOfCustomOfficialLine) {
    returnData.allowUseOfCustomOfficialLine = data.allowUseOfCustomOfficialLine;
  }

  if (data.lineOfficialAccountChannelAccessToken) {
    returnData.lineOfficialAccountChannelAccessToken =
      data.lineOfficialAccountChannelAccessToken;
  }

  if (data.lineOfficialAccountChannelSecret) {
    returnData.lineOfficialAccountChannelSecret =
      data.lineOfficialAccountChannelSecret;
  }

  if (data.liffId) {
    returnData.liffId = data.liffId;
  }

  if (data.zoomUserId) {
    returnData.zoomUserId = data.zoomUserId;
  }

  if (data.role) {
    returnData.role = data.role;
  }

  if (data.parentSchoolDocIds) {
    returnData.parentSchoolDocIds = data.parentSchoolDocIds;
  }

  if (typeof data.useSchoolAiExclusively === "boolean") {
    returnData.useSchoolAiExclusively = data.useSchoolAiExclusively;
  } else {
    returnData.useSchoolAiExclusively = false;
  }

  returnData.hideLoginInvitaion = data.hideLoginInvitaion ?? false;
  returnData.hideMingakuStudyRoom = data.hideMingakuStudyRoom ?? false;
  returnData.allowStudentsToChangePassword =
    data.allowStudentsToChangePassword ?? false;
  returnData.isPromptCreationForbidden =
    data.isPromptCreationForbidden ?? false;

  return {
    ref,
    data: returnData
  };
}

export function convertToSchoolSecret(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): SchoolSecret {
  const returnData: SchoolSecretData = {
    customerId: data.customerId,
    paymentMethods: data.paymentMethods
  };

  return {
    ref,
    data: returnData
  };
}
