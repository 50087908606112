import {
  convertToCustomProperty,
  CustomProperty,
  customPropertyCollectionKey,
  CustomPropertyData,
  CustomPropertyType
} from "@/entities/custom_property";
import firebase from "firebase/app";
import "firebase/firestore";

export async function createCustomProperty(
  schoolRef: firebase.firestore.DocumentReference,
  customPropertyData: CustomPropertyData
): Promise<void> {
  const {
    title,
    type,
    shareToAdmin,
    choices,
    showToStudent,
    editableByStudent
  } = customPropertyData;

  const data: CustomPropertyData = {
    title,
    type,
    shareToAdmin,
    choices: type === "select" ? choices : [],
    showToStudent,
    editableByStudent: showToStudent ? editableByStudent : false
  };

  await schoolRef.collection(customPropertyCollectionKey).add(data);
}

export async function fetchCustomPropertiesOfSchool(
  schoolRef: firebase.firestore.DocumentReference,
  isAdmin?: boolean
): Promise<CustomProperty[]> {
  const snapshot = await schoolRef
    .collection(customPropertyCollectionKey)
    .get();
  if (snapshot.empty) {
    return [];
  }

  const customProperties = snapshot.docs.map(doc =>
    convertToCustomProperty(doc.data(), doc.id, doc.ref)
  );
  if (isAdmin) {
    return customProperties.filter(item => item.data.shareToAdmin);
  } else {
    return customProperties;
  }
}

export async function updateCustomProperty(
  propertyRef: firebase.firestore.DocumentReference,
  data: {
    title?: string;
    type?: CustomPropertyType;
    shareToAdmin: boolean;
    choices?: string[];
    showToStudent: boolean;
    editableByStudent: boolean;
  }
): Promise<void> {
  // 「生徒が閲覧できないのに編集できる」という状況を避ける
  const _editableByStudent = data.showToStudent
    ? data.editableByStudent
    : false;
  data.editableByStudent = _editableByStudent;
  await propertyRef.update(data);
}

export async function deleteCustomProperty(
  propertyRef: firebase.firestore.DocumentReference
): Promise<void> {
  await propertyRef.delete();
}
