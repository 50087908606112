import firebase from "firebase/app";

export const customPropertyCollectionKey = "customProperties";

export type CustomPropertyType = "string" | "number" | "select";

export type CustomProperty = {
  ref: firebase.firestore.DocumentReference;
  id: string;
  data: CustomPropertyData;
};

export type CustomPropertyData = {
  title: string; // タイトル
  type: CustomPropertyType; // オリジナル情報の値の種類
  shareToAdmin: boolean; // 管理人に共有するかどうか
  choices: string[]; // 選択肢 (type = "select" 以外は空)
  showToStudent: boolean;
  editableByStudent: boolean;
};

export function convertToCustomProperty(
  data: firebase.firestore.DocumentData,
  id: string,
  ref: firebase.firestore.DocumentReference
): CustomProperty {
  const returnData: CustomPropertyData = {
    title: data.title,
    type: data.type,
    shareToAdmin: data.shareToAdmin,
    choices: data.choices,
    showToStudent: data.showToStudent ?? false,
    editableByStudent: data.editableByStudent ?? false
  };

  return {
    ref,
    id,
    data: returnData
  };
}
